import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'BussinessGroupMap' })
export class BussinessGroupPipe implements PipeTransform {

     private bussinessGroupdictionary = {
        'CMT': 'Communication, Media, and Technology',
        'H&PS': 'Health and Public Services',
        'FS': 'Financial Services',
        'Products': 'Products',
        'Resources': 'Resources',
        'ICI': 'Infrastructure Engineering',
        'IES': 'Software Engineering',
        'TDP': 'Technology Development Program'
      };

    transform(key: string) {
    return this.bussinessGroupdictionary[key];
  }
}