export const environment = {
  production: true,
  serviceUrl: 'https://api.mynomination.neies.net/',
  config: {
    clientId: '0oa1uvoqk1SxSIqgz5d7',
    issuer: 'https://dev-39724836.okta.com/oauth2/default',
    redirectUri: 'https://mynomination.neies.net/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  },
  apiKey: '',
};
