<mat-card class="mat-card">
<mat-card-title class="mat-card-header">Login</mat-card-title>
<mat-card-content>
<form [formGroup]="form" (ngSubmit)="submit()">
  <p>
    <mat-form-field>
      <input type="text" matInput placeholder="Username" formControlName="username">
    </mat-form-field>
  </p>

  <p>
    <mat-form-field>
      <input type="password" matInput placeholder="Password" formControlName="password">
    </mat-form-field>
  </p>

  <p *ngIf="errorLabel" class="error">
    {{ errorLabel }}
  </p>

  <div class="button">
    <button type="submit" mat-button color="primary">Login</button>
    <button mat-button color="accent" (click) = "close(); $event.preventDefault()">Cancel</button>
 </div>

</form>
</mat-card-content>
</mat-card>
