import { Injectable } from '@angular/core';
import { NominationProgram } from '../core/models/nomination-program.model';

@Injectable({
    providedIn: 'root',
  })
  
export class Util {
   sortProgramms(programm: NominationProgram[]) {
    return programm.sort((a, b) => {
      if (a.startDate && b.startDate) {
        const dt1 = Date.parse(a.startDate);
        const dt2 = Date.parse(b.startDate);

        if (dt1 < dt2) return 1;
        if (dt1 > dt2) return -1;
      }
      return 0;
    });
  }

  sortUpcomingToLatest(programm: NominationProgram[]): any {
    if (programm) {
      let now: Date = new Date();

      programm.sort((a: any, b: any) => {
        let date1: Date = new Date(a.startDate);
        let date2: Date = new Date(b.startDate);

        // If the first date passed
        if(date1 < now){
          // If the second date passed
          if(date2 < now){
            return 0
          } else {
            return 1
          }
        } else {
          // If the second date passed
          if(date2 < now) {
            return -1
          } else if(date1 < date2) {
            return -1
          } else if(date1 > date2) {
            return 1
          } else {
            return 0;
          }
        }
      });
    }

    return programm;
  }
}
