<div class="row divparent">
    <div class="col-12">
        <mat-card class="mat-card">
            <div class="row">
              <div class="col-12 excel">
                <button (click)="addProgram()" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Add Program</button>
              </div>
            </div>
            <div class="mat-elevation-z8" #TABLE>
                <table mat-table #table [dataSource]="dataSource">
                 <!-- Name Column -->
                  <ng-container matColumnDef="programId">
                    <th mat-header-cell *matHeaderCellDef> Program Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.programId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="userId">
                    <th mat-header-cell *matHeaderCellDef> Created By </th>
                    <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
                  </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>
              
                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef> Start Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDate| date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef> End Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.endDate| date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="view">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="accent"  (click) ="view(element.description)">View</button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="publish">
                    <th mat-header-cell *matHeaderCellDef>Publish</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-slide-toggle #toggleElement (change)="publish($event,element.programId)" [disabled]='element.isPublished || element.status === 3' [(checked)]="element.isPublished" aria-checked="element.isPublished"></mat-slide-toggle>
                      <span [hidden]="true"> {{element.isPublished}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="close">
                    <th mat-header-cell *matHeaderCellDef>Registartion Closed</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-slide-toggle #toggleElement (change)="closeRegistration($event,element.programId)" [(checked)]="element.isClosed" aria-checked="element.isClosed"></mat-slide-toggle>
                      <span [hidden]="true"> {{element.isClosed}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status | status }} </td>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="accent"  (click) ="edit(element.programId)">Edit</button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="accent" [disabled]="true" (click) ="confirmDialog(element.programId)">Delete</button>
                    </td>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                  <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                    <mat-progress-spinner 
                      color="primary" 
                      mode="indeterminate">
                    </mat-progress-spinner>
                </div>
                <mat-paginator [pageSizeOptions]="[10,20,30,40]" showFirstLastButtons></mat-paginator>
              </div>
        </mat-card>
    </div>
 </div>

