
<div class="container">
 <div class="center">
    <mat-card class="mat-card">
        <div class="row">
            <div class="col-12 col-sm-12">
                <mat-card-title class="mat-card-header">Nomination for {{programName}}</mat-card-title>
            </div>
            <mat-card-content>
                <div class="row date">
                  <div class="col"> 
                    <mat-label>Start Date: <b> {{startDate| date}}</b></mat-label>&nbsp;
                    <mat-label>End Date: <b>{{endDate| date}}</b></mat-label>
                 </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="col-12 col-sm-12">
                       <div class="row">
                         <div class="col-12">
                            <img [src]="imageSrc" *ngIf="imageSrc" style="width:100%;height:200px">
                         </div> 
                       </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Name" formControlName="name">
                                    </mat-form-field>
                                  </p>
                               </div>
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Client Name" formControlName="clientName">
                                    </mat-form-field>
                                  </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Project" formControlName="project">
                                    </mat-form-field>
                                  </p>
                            </div>
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Manager Enterprise Id" formControlName="managerId">
                                    </mat-form-field>
                                  </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Your Enterprise Id(xx@accenture.com)" formControlName="enterpriseId">
                                    </mat-form-field>
                                  </p>
                               </div>
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Home Office" formControlName="location">
                                    </mat-form-field>
                                  </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                 <mat-form-field appearance="fill">
                                      <mat-label>Select your client role (required)</mat-label>
                                      <select matNativeControl required [formControl]="selectFormControlRole" [(value)]="role" [(ngModel)]="role">
                                        <option label="--select--"></option>
                                        <option value="Product Owner">Product Owner</option>
                                        <option value="Scrum Master">Scrum Master</option>
                                        <option value="Architect">Architect</option>
                                        <option value="Designer">Designer</option>
                                        <option value="Bussiness Analyst">Bussiness Analyst</option>
                                        <option value="System Analyst">System Analyst</option>
                                        <option value="Tech Lead">Tech Lead</option>
                                        <option value="Developer">Developer</option>
                                        <option value="QA">QA</option>
                                      </select>
                                      <mat-error *ngIf="selectFormControlRole.hasError('required')">
                                        This field is required
                                      </mat-error>
                                      <mat-hint>You can pick up your role here</mat-hint>
                                    </mat-form-field>
                               </div>
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Primary Skill" formControlName="primarySkill">
                                    </mat-form-field>
                                  </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                 <mat-form-field appearance="fill">
                                      <mat-label>Select your level (required)</mat-label>
                                      <select matNativeControl required [formControl]="selectFormControlLevel" [(value)]="level" [(ngModel)]="level">
                                        <option label="--select--"></option>
                                        <option value="Senior Manager">Senior Manager</option>
                                        <option value="Manager">Manager</option>
                                         <option value="Associate Manager">Associate Manager</option>
                                        <option value="Team Lead">Team Lead</option>
                                        <option value="Analyst">Analyst</option>
                                        <option value="Senior Software Engineer">Senior Software Engineer</option>
                                        <option value="Associate Software Engineer">Associate Software Engineer</option>
                                      </select>
                                      <mat-error *ngIf="selectFormControlLevel.hasError('required')">
                                        This field is required
                                      </mat-error>
                                      <mat-hint>You can pick up your level here</mat-hint>
                                    </mat-form-field>
                               </div>
                            <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Secondary Skill" formControlName="secondarySkill">
                                    </mat-form-field>
                                  </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <p>
                                  <mat-form-field appearance="fill">
                                    <mat-label>Select your Industry (required)</mat-label>
                                    <select matNativeControl required [formControl]="selectFormControlBusinessGroup" [(value)]="bussinessGroup" [(ngModel)]="bussinessGroup">
                                      <option label="--select--"></option>
                                      <option value="CMT">Communication, Media, and Technology</option>
                                      <option value="H&PS">Health and Public Services</option>
                                      <option value="FS">Financial Services</option>
                                      <option value="Products">Products</option>
                                      <option value="Resources">Resources</option>
                                      <option value="ICI">Infrastructure Engineering</option>
                                      <option value="IES">Software Engineering</option>
                                      <option value="TDP">Technology Development Program</option>
                                    </select>
                                    <mat-error *ngIf="selectFormControlBusinessGroup.hasError('required')">
                                      This field is required
                                    </mat-error>
                                    <mat-hint>You can pick up your industry here</mat-hint>
                                  </mat-form-field>
                                  </p>
                               </div>
                               <div class="col-6 col-sm-6">
                                <p>
                                    <mat-form-field>
                                      <input type="text" matInput placeholder="Training Approver" formControlName="approver">
                                    </mat-form-field>
                                  </p>
                            </div>
                          </div>
                        <div class="row pt-2">
                            <div class="col-12">
                              <div class="row">
                                <label>Are you on a project, billable?</label> 
                              </div>
                              <div class="row">
                                <mat-radio-group aria-label="projectBillableOptions" formControlName="projectBillableOptions">
                                  <mat-radio-button value="true" [checked]='true' (change)="ProjectBillableChanged($event)">Yes</mat-radio-button>
                                  <mat-radio-button value="false" (change)="ProjectBillableChanged($event)">No</mat-radio-button>
                                </mat-radio-group>                         
                              </div>     
                              <div class="row">
                                <mat-checkbox formControlName="projectBillableCheck" [(ngModel)]="projectBillableCheck" *ngIf = "projectBillableVisible">
                                  I confirm to adjust my client work and will remain fully chargeable for the days I am attending this training
                              </mat-checkbox>  
                              <mat-error *ngIf="!projectBillableCheck && projectBillableVisible">
                                This field is required
                              </mat-error>                           
                              </div>  
                             </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox [(ngModel)]="agreechecked" (change)="checkValue(agreechecked)"  [(checked)]="agreechecked" formControlName="selectFormControlAgree">
                                    I Agree to Terms and Policies
                                </mat-checkbox>
                             </div>
                        </div>
                        <div class="row">
                            <div class="col-12 submit">
                                <button type="submit" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Submit</button>
                             </div>
                        </div> 
                    </div>
                </form>
            </mat-card-content>
        </div>
        
  </mat-card>
  </div>
 </div>