import { Component, OnInit } from '@angular/core';
import { User } from '../core/models/user.model';
import { ProgramService } from '../core/program.service';
import { ProgramCategory } from '../core/models/program-category.model';
import { CategoryCard } from '../core/models/category-card.model';
import { NominationProgram } from '../core/models/nomination-program.model';
import { Util } from '../utils/util';

@Component({
  selector: 'app-category-container',
  templateUrl: './category-container.component.html',
  styleUrls: ['./category-container.component.scss']
})
export class CategoryContainerComponent implements OnInit {

  userCategories:CategoryCard[] = [];
  programms: NominationProgram[] = [];
  programCategories: ProgramCategory[] = [];
  isLoading : boolean = false;

  constructor( private _service: ProgramService,
    private util : Util) { }

  ngOnInit(): void {
    this.getAllProgramsCategories();
  }

  getAllProgramsCategories(){
    this.isLoading = true;
    let user:User = new User ();
    user.userId = sessionStorage.getItem("userId");
    user.categoryId = sessionStorage.getItem("userCategories").split(",");
    this._service.GetAllProgramsCategories(user).subscribe(
      response => {
        if(response !=null && response.length > 0){
          this.programCategories = response as ProgramCategory[];
          this.getCategoryCardDetails();
         }
      },
      error => {
        console.log(error)
      } 
     );
  }

  getCategoryCardDetails(){
    let user: User = new User();
    user.userId = sessionStorage.getItem("userId");
    user.categoryId = sessionStorage.getItem("userCategories").split(",");

    this._service.getPrograms(user).subscribe(
      response => {
        if (response != null && response.length > 0) {         
          const userPrograms = (this.util.sortUpcomingToLatest(response) as NominationProgram[]);
          this.programCategories.forEach(category => {         
            const program = userPrograms.filter(x=>x.categoryId == category.categoryId)[0];
            const categoryCard = new CategoryCard();
            categoryCard.categoryId = category.categoryId;
            categoryCard.categoryName = category.category;
            if(program && new Date(program.startDate) > new Date()) {             
              categoryCard.programId = program.programId;
              categoryCard.programName = program.name;             
            }
            this.userCategories.push(categoryCard);          
          });
          this.isLoading = false

        }
      },
      error => {
        console.log(error)
      }
    );    
  }
}
