
<div class="col-10 container pd-top">
    <div class="row">
        <div *ngFor='let category of userCategories' class="col-md-4 col-lg-4">
          <app-category-card [category]="category"></app-category-card>
        </div>
    </div>
</div>

<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
    <mat-progress-spinner 
      color="primary" 
      mode="indeterminate">
    </mat-progress-spinner>
</div>
