import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import {MatDialog} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { ObervableService } from 'src/app/core/obeservable.service';
import { Router, RouterLink } from '@angular/router';
import { ProgramService } from 'src/app/core/program.service';
import { NominationService } from 'src/app/core/nomination.service';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
 
  @ViewChild("toggleElement") ref: any;
  checked : boolean = false;
  slideLabel:string = "Log In";
  isViewVisbile : boolean = false;
  isSetupVisbile : boolean = false;
  isBackupVisbile : boolean = false;

  isAuthenticated: boolean;
  welcomeMssg: string = "";
  userClaims: any;
  groups: string[] = [];

  constructor(public dialog: MatDialog,
     private _service: ProgramService,
     private _nominationService: NominationService,
     private router: Router,
     public oktaAuth: OktaAuthService
     ) {
   
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
      } 
    );
   }

   async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
       
    if(this.isAuthenticated){
      this.userClaims = await this.oktaAuth.getUser();
      console.log(this.userClaims);

      this.welcomeMssg = "Welcome " + this.userClaims.given_name;
      this.checked = true;
      this.slideLabel = "Log out";
      this.groups = this.userClaims.Groups;

      sessionStorage.setItem("userCategories",this.groups.toString());

      if(sessionStorage.getItem("isAuthenticated") && sessionStorage.getItem("isAuthenticated") === 'false'){
        sessionStorage.setItem("isAuthenticated",'true');
        this.router.navigate(['/category'])
      }
      

       this.isViewVisbile = (this.groups.length > 0) ;
       this.isSetupVisbile = (this.groups.length > 0) ;
       this.isBackupVisbile = this.groups.indexOf('superadmin') > -1;
     }   
  }

  async toggle(event: MatSlideToggleChange) {
    console.log('Toggle fired');
    if( event.checked){
      this.slideLabel = "Log out";
     this.oktaAuth.signInWithRedirect({
      originalUri: '/'
     });  

    }else{
      this.slideLabel = "Log in";
      sessionStorage.setItem("isAuthenticated",'false');
      await this.oktaAuth.signOut();
    }
  }

  openView(){
    this.router.navigate(['/view', {categoryId: JSON.stringify('All')}]);
  }

  backUp(){

    let todaysDate = new Date();

    this._nominationService.getAllNominations().subscribe(
      response => {
        if(response !=null){
          this.dyanmicDownloadByHtmlTag({
            fileName: 'MyNominations' + todaysDate.getDate() + todaysDate.getMonth() + + todaysDate.getFullYear(),
            text: JSON.stringify(response)
          });
         }
      },
      error => {
        console.log(error)
      } 
     );

     this._service.getAllPrograms().subscribe(
      response => {
        if(response !=null){
          this.dyanmicDownloadByHtmlTag({
            fileName: 'MyPrograms'  + todaysDate.getDate() + todaysDate.getMonth() + + todaysDate.getFullYear(),
            text: JSON.stringify(response)
          });
         }
      },
      error => {
        console.log(error)
      } 
     );
    
  }

  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  openSetup(){
    this.router.navigate(['/viewsetup'])
  }
  
  openUsers(){
    this.router.navigate(['/users'])
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let isSuccefull = JSON.parse(sessionStorage.getItem("isLoginSuccessfull"));

      if(isSuccefull){
        this.ref._checked = true;
        this.slideLabel = "Log out";
      }else{
        this.ref._checked = false;
        this.slideLabel = "Log in";
        this.isSetupVisbile = false;
        this.isViewVisbile = false;
        this.isBackupVisbile = false;
      }
    });
  }

  ngOnDestroy() {
    
  }

}
