export class CategoryCard {
    categoryName: string;
    categoryId: string;
    programName: string;
    programId: string;
  
    constructor() {
      this.categoryName = '';
      this.programName = '';
      this.programId = '';
      this.categoryId = '';
    }
  }
