<div id="primary-header" role="banner" data-bind="visible: showHeader">
   
    <div class="row">
       <div class="col-1 col-sm-1 left md-display-3" style="padding: 10px;">
         <span id="logo-container" tabindex="0">
            <div id="logo" tabindex="0" role="button">
      
            </div>
         </span>
       </div>
      <div class="col-6 col-sm-6 left md-display-3"><h1>My Nomination</h1></div>
      <div class="col-5 col-sm-5 right">
         <button *ngIf="isViewVisbile" (click)="openView()"  class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">View</button>
         <button *ngIf="isSetupVisbile" (click)="openSetup()"   class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">Setup</button>
         <button *ngIf="isBackupVisbile" (click)="backUp()"   class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">Backup DB</button>
         <mat-label color="pink"><b>{{welcomeMssg}}</b></mat-label> &nbsp;
         <mat-slide-toggle #toggleElement (change)="toggle($event)" [(checked)]="checked" aria-checked="checked">{{slideLabel}}</mat-slide-toggle>
      </div>
   </div>
</div>
