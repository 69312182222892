import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryCard } from 'src/app/core/models/category-card.model';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {

  @Input() category: CategoryCard;
  categoryName: string = "";
  programName: string = ""

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.categoryName = this.category.categoryName;
    if(this.category.programName) {
      this.programName = `${this.category.programName.substring(0, 50)}....`;
    }    
  }
  
  viewNominations(){
    this.router.navigate(['/view', {programId: JSON.stringify(this.category.programId)}]);
  }

  viewCategory(){
    this.router.navigate(['/view', {categoryId: JSON.stringify(this.category.categoryId)}]);
  }  

}
