
<div class="container">
    <div class="col-12 col-sm-12 center">
       <mat-card class="mat-card width">
           <div class="row">
               <div class="col-12 col-sm-12">
                   <mat-card-title class="mat-card-header">Program Setup</mat-card-title>
               </div>
               <mat-card-content>
                   <form [formGroup]="setupForm" (ngSubmit)="submit()">
                       <div class="col-12 col-sm-12 width">
                           <div class="row">
                               <div class="col-6 col-sm-6">
                                   <p>
                                       <mat-form-field>
                                         <input type="text" matInput placeholder="Name" formControlName="name" [(ngModel)]="program.name">
                                       </mat-form-field>
                                     </p>
                                  </div>
                          </div>
                           <div class="row">
                               <div class="col-6 col-sm-6">
                                   <p>
                                    <mat-form-field appearance="fill" color="primary">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput [matDatepicker]="startDatePicker" [min] = "minStartDate" formControlName="startDate" [(ngModel)]="program.startDate">
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker startView="month" color="accent" [startAt]="startDate" 
                                    maxDate="maxStartDate"></mat-datepicker>
                                    </mat-form-field>
                                  </p>
                                  </div>
                               <div class="col-6 col-sm-6">
                                   <p>
                                    <mat-form-field appearance="fill" color="primary">
                                    <mat-label>End Date</mat-label>
                                    <input matInput [matDatepicker]="endDatePicker" [min] = "program.startDate"  formControlName="endDate" [(ngModel)]="program.endDate">
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker startView="month" color="accent" [startAt]="endDate"></mat-datepicker>
                                   </mat-form-field>  
                                  </p>
                               </div>
                           </div>
                           <div class="row">
                            <div class="col-12 col-sm-12">
                                <p>
                                  <mat-form-field class="textarea">
                                    <textarea class="textarea" matInput placeholder="Course Agenda..." formControlName="courseAgenda" [(ngModel)]="program.courseAgenda"></textarea>
                                  </mat-form-field>
                                </p>
                             </div>
                           </div>
                           <div class="row">
                               <div class="col-12 col-sm-12">
                                   <div class="row">
                                       <div class="col-12">
                                        <label for="file">Banner</label>
                                        <input 
                                            formControlName="file"
                                            id="file" 
                                            type="file" 
                                            class="form-control"
                                            (change)="onFileChange($event)">
                                            <button type="button" *ngIf="imageSrc" mat-button color="accent" (click) ="view(); $event.preventDefault()">View Banner</button>
                                           <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                            <div *ngIf="f.file.errors.required">File is required.</div>
                                          </div>
                                       </div>
                                   </div>
                                     
                                       
                                   </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-sm-12 ">
                                    <p>
                                         <mat-form-field appearance="fill" class="select">
                                            <mat-label>Select your program category (required)</mat-label>
                                            <mat-select matNativeControl required [formControl]="selectFormControlCategory" [value]="selectedCategory" [(ngModel)]="selectedCategory" (selectionChange)="changeCategory($event.value)">
                                                <mat-option *ngFor="let category of programCategories" [value]="category.categoryId">
                                                    {{category.category}}
                                                  </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="selectFormControlCategory.hasError('required')">
                                              This field is required
                                            </mat-error>
                                            <mat-hint>You can pick up your program category here</mat-hint>
                                          </mat-form-field>
                                      </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-sm-12">
                                    <p>
                                        <mat-form-field class="textarea">
                                          <textarea matInput class="textarea" placeholder="Description..." formControlName="description" [(ngModel)]="program.description"></textarea>
                                        </mat-form-field>
                                      </p>
                                </div>
                              </div>
                            <div class="row padding">
                               <div class="col-12 submit">
                                   <button type="submit" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Submit</button>
                                </div>
                           </div> 
                       </div>
                   </form>
               </mat-card-content>
           </div>
           
     </mat-card>
     </div>
    </div>
