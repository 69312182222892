import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _apiUrl: string;

  get apiUrl(): string {
    return this._apiUrl;
  }

  constructor() { }

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    this._apiUrl = environment.serviceUrl;
  }
}