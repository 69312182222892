<div class="row divparent">
    <div class="col-12">
        <mat-card class="mat-card">
            <div class="row">
              <div class="col-12 excel">
                <button (click)="addUser()" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Add User</button>
              </div>
            </div>
            <div class="mat-elevation-z8" #TABLE>
                <table mat-table #table [dataSource]="dataSource">
                 <!-- Name Column -->
                  <ng-container matColumnDef="userId">
                    <th mat-header-cell *matHeaderCellDef> User Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef> Role </th>
                    <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                  </ng-container>
              
                 <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="accent"  (click) ="editUser(element.programId)">Edit</button>
                    </td>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              
                <mat-paginator [pageSizeOptions]="[10,20,30,40]" showFirstLastButtons></mat-paginator>
              </div>
        </mat-card>
    </div>
 </div>


