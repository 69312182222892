import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesComponent } from './courses/courses.component';
import { MyNominationContainerComponent } from './my-nomination-container/my-nomination-container.component';
import { NominationComponent } from './nomination/nomination.component';
import { SetupProgramComponent } from './setup-program/setup-program.component';
import { MessageComponent } from './shared/message/message.component';
import { UserComponent } from './user/user.component';
import { ViewNominationsComponent } from './view-nominations/view-nominations.component';
import { ViewSetupComponent } from './view-setup/view-setup.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CategoryContainerComponent } from './category-container/category-container.component';

const CALLBACK_PATH = 'login/callback';


const routes: Routes = [
  { path: '', component: MyNominationContainerComponent ,
  children: [
    { path: 'nomination/:id', component: NominationComponent },
    { path: 'courses', component: CoursesComponent },
    { path: 'view', component: ViewNominationsComponent, canActivate: [ OktaAuthGuard ]},
    { path: 'setup', component: SetupProgramComponent, canActivate: [ OktaAuthGuard ] },
    { path: 'viewsetup', component: ViewSetupComponent, canActivate: [ OktaAuthGuard ] },
    { path: 'users', component: UserComponent, canActivate: [ OktaAuthGuard ] },
    { path: 'message', component: MessageComponent },
    { path: 'category', component: CategoryContainerComponent }
  ]},
  {
    path: CALLBACK_PATH,
    component: OktaCallbackComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
