<div class="container">
    <mat-card class="mat-card width">
        <div class="row">
            <div class="col-12 col-sm-12">
                <mat-card-title class="mat-card-header">View Banner</mat-card-title>
            </div>
            <mat-card-content>
                <img [src]="imageSrc" *ngIf="imageSrc" style="height: 300px; width:500px">
            </mat-card-content>
        </div>
    </mat-card>
</div>

