<h2 matDialogTitle>Move Nominations</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-card">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12"> 
                                <mat-form-field appearance="fill">
                                    <mat-label>Select your Program</mat-label>
                                    <mat-select matNativeControl id="myProgram" [value]="selectedOption" (selectionChange)="onChange($event?.source?.value)">
                                      <mat-option *ngFor="let program of programms" [value]="program.programId">
                                          {{ program.name }}
                                        </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
               <div class="row">
                <div class="col-10">
                    <div class="row">
                        <div class="col-6"> 
                            <mat-label>Start Date: <b> {{program?.startDate| date}}</b></mat-label>
                         </div>
                        <div class="col-6">
                            <mat-label>End Date: <b>{{program?.endDate| date}}</b></mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6"> 
                           <mat-label>Program Category: <b>{{program?.category}}</b></mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label>Description: <b>{{program?.description}}</b></mat-label>
                        </div>
                    </div>
                </div>
               </div>
            </mat-card>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  (click)="moveNominations()"  class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">Move</button>
    <button  (click)="close()"  class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">Close</button>
</mat-dialog-actions>

