<div class="divparent">
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-card">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                              <mat-form-field appearance="fill">
                                <mat-label>Select Start Year</mat-label>
                                <mat-select matNativeControl id="myYear" [value]="selectedYear" (selectionChange)="onYearChange($event?.source?.value)">
                                  <mat-option *ngFor="let year of years" [value]="year">
                                      {{ year }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-9"> 
                                <mat-form-field appearance="fill">
                                    <mat-label>Select your Program</mat-label>
                                    <mat-select matNativeControl id="myProgram" [value]="selectedOption" (selectionChange)="onProgramChange($event?.source?.value)">
                                      <mat-option *ngFor="let program of programmsForYear" [value]="program.programId">
                                          {{ program.name }}
                                        </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>                         
                        </div>
                    </div>
                </div>
               <div class="row">
                <div class="col-10">
                    <div class="row">
                        <div class="col-2"> 
                            <mat-label>Start Date: <b> {{program?.startDate| date}}</b></mat-label>
                         </div>
                        <div class="col-2">
                            <mat-label>End Date: <b>{{program?.endDate| date}}</b></mat-label>
                        </div>
                        <div class="col-3"> 
                           <mat-label>Program Category: <b>{{program?.category}}</b></mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label>Description: <b>{{program?.description}}</b></mat-label>
                        </div>
                    </div>
                </div>
               </div>
            </mat-card>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <mat-card class="mat-card">
                <div class="row">
                  <div class="col-12 excel">
                    <button (click)="moveNominations()"  [disabled]="nominationsMove.length <= 0 || nominations.length <= 0" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Move</button> &nbsp;
                    <button (click)="exportToExcel()"  [disabled]="nominations.length <= 0" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" mat-button color="accent">Export To Excel</button>
                  </div>
                </div>
                <div class="mat-elevation-z8" #TABLE>
                  <div class="table-responsive">
                    <table mat-table #table [dataSource]="dataSource">
                     <!-- Name Column -->
                     <ng-container matColumnDef="move">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-checkbox class="example-margin" (change)="showOptions($event,element)"></mat-checkbox>
                      </td>
                    </ng-container>
                      &nbsp;
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                      </ng-container>
                  
                      <!-- Weight Column -->
                      <ng-container matColumnDef="enterpriseId">
                        <th mat-header-cell *matHeaderCellDef> Enterprise Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.enterpriseId}} </td>
                      </ng-container>
                  
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="bussinessGroup">
                        <th mat-header-cell *matHeaderCellDef> Industry </th>
                        <td mat-cell *matCellDef="let element"> {{element.bussinessGroup | BussinessGroupMap}} </td>
                      </ng-container>

                      <ng-container matColumnDef="level">
                        <th mat-header-cell *matHeaderCellDef> Level </th>
                        <td mat-cell *matCellDef="let element"> {{element.level}} </td>
                      </ng-container>

                      <ng-container matColumnDef="clientName">
                        <th mat-header-cell *matHeaderCellDef> Client Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="project">
                        <th mat-header-cell *matHeaderCellDef> Project </th>
                        <td mat-cell *matCellDef="let element"> {{element.project}} </td>
                      </ng-container>

                      <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef> Home Office </th>
                        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                      </ng-container>

                      <ng-container matColumnDef="managerId">
                        <th mat-header-cell *matHeaderCellDef> Manager Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.managerId}} </td>
                      </ng-container>

                      <ng-container matColumnDef="approved">
                        <th mat-header-cell *matHeaderCellDef>Approved</th>
                        <td mat-cell *matCellDef="let element">
                          <mat-slide-toggle #toggleElement (change)="toggle($event,element.programId,element.enterpriseId)" [(checked)]="element.approved" aria-checked="element.approved"></mat-slide-toggle>
                          <span [hidden]="true"> {{element.approved}} </span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="programId" >
                        <th mat-header-cell *matHeaderCellDef [hidden]="true">Program Id</th>
                        <td mat-cell *matCellDef="let element" [hidden]="true">
                          <span [hidden]="true"> {{element.programId}} </span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="role" >
                        <th mat-header-cell *matHeaderCellDef [hidden]="true">Role</th>
                        <td mat-cell *matCellDef="let element" [hidden]="true">
                          <span [hidden]="true"> {{element.role}} </span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="secondarySkill">
                        <th mat-header-cell *matHeaderCellDef [hidden]="true">Secondary Skill</th>
                        <td mat-cell *matCellDef="let element" [hidden]="true">
                          <span [hidden]="true"> {{element.secondarySkill}} </span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="primarySkill">
                        <th mat-header-cell *matHeaderCellDef [hidden]="true">Primary Skill</th>
                        <td mat-cell *matCellDef="let element" [hidden]="true">
                         <span [hidden]="true"> {{element.primarySkill}} </span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-button color="accent" (click) ="view(element.programId,element.enterpriseId)">View</button>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-button color="accent" [disabled]="true" (click) ="confirmDialog(element.programId,element.enterpriseId)">Delete</button>
                        </td>
                      </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
                      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-spinner 
                          color="primary" 
                          mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10,20,30,40]" showFirstLastButtons></mat-paginator>
                  </div>
            </mat-card>
        </div>
    </div>
  </div>
