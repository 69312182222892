<mat-card class="mat-card width">
    <div class="row">
        <div class="col-12 col-sm-12 center">
            <mat-card-title class="mat-card-header">Nomination Detail</mat-card-title>
        </div>
        <mat-card-content class="width">
           <div class="col-12">
                    <div class="row">
                        <div class="col-4">
                            <p>
                                <mat-label>Name: <b> {{nomination?.name}}</b></mat-label>
                              </p>
                           </div>
                        <div class="col-8">
                            <p>
                                <mat-label>Client Name: <b> {{nomination?.clientName}}</b></mat-label>
                              </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <p>
                                <mat-label>Project: <b> {{nomination?.project}}</b></mat-label>
                              </p>
                        </div>
                        <div class="col-8">
                            <p>
                                <mat-label>Manager Enterprise Id: <b> {{nomination?.managerId}}</b></mat-label>
                              </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <p>
                                <mat-label>Enterprise ID: <b> {{nomination?.enterpriseId}}</b></mat-label>
                              </p>
                           </div>
                        <div class="col-8">
                            <p>
                                <mat-label>Home Office: <b> {{nomination?.location}}</b></mat-label>
                              </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <mat-label>Role: <b> {{nomination?.role}}</b></mat-label>
                           </div>
                        <div class="col-8">
                            <p>
                                <mat-label>Primary Skill: <b> {{nomination?.primarySkill}}</b></mat-label>
                              </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <mat-label>Level: <b> {{nomination?.level}}</b></mat-label>
                           </div>
                        <div class="col-8">
                            <p>
                                <mat-label>Secondary Skill: <b> {{nomination?.secondarySkill}}</b></mat-label>
                              </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <p>
                                <mat-label>Industry: <b> {{nomination?.bussinessGroup | BussinessGroupMap}}</b></mat-label>
                              </p>
                           </div>
                           <div class="col-8">
                            <p>
                                <mat-label>Training Approver: <b> {{nomination?.approver}}</b></mat-label>
                              </p>
                           </div>
                  </div>
                    <div class="row">
                        <div class="col-12 center">
                            <button  (click)="close()"  class="mat-focus-indicator mat-raised-button mat-button-base mat-primary btnmargin" mat-button color="accent">Close</button>
                        </div>
                    </div>
                </div>
        </mat-card-content>
    </div>
    
</mat-card>
