import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
  })
export class CommonService {

    accessToken : string = "" ;

    constructor(private _http: HttpClient,
        public oktaAuth: OktaAuthService) {

            this.accessToken = this.oktaAuth.getAccessToken();

         }

    get(url : string) : Observable<any> {
         const header = new HttpHeaders()
        .set('Content-type', 'application/json')
        .set('Authorization','Bearer ' + this.accessToken)
        .set('x-api-key',environment.apiKey);
        return this._http.get(url,{ headers: header})
    }

    post(url : string, body: any) :Observable<any> {
        const header = new HttpHeaders()
        .set('Content-type', 'application/json')
        .set('Authorization','Bearer ' + this.accessToken)
        .set('x-api-key',environment.apiKey);

        return this._http.post(url, body,{ headers: header});
    }

}